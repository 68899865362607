import React, { useState } from 'react';
import { Button, Modal, Form, Checkbox, Loader } from 'semantic-ui-react';
import toastr from 'toastr';
import apis from '../../../../../../../../../../apis';
import { API_NAMES } from '../../../../constants/apiNames';

const PassportDocumentType = ({ applicant, api }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    book: applicant?.documentTypeForPDF?.book || false,
    card: applicant?.documentTypeForPDF?.card || false
  });

  const handleChange = (e, { name, checked }) => {
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleSubmit = async () => {   
    setLoading(true);
    try { 
      
      if (![API_NAMES.DS11, API_NAMES.DS82].includes(api)) {
        toastr.error('Invalid product type');
        return;
      }

      await apis.applicantDocumentHandler.updateDocumentTypeForPdf(api, applicant.id, formData);
      
      toastr.success('Document type updated successfully');
      setOpen(false); 
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      toastr.error(`Failed to update document type: ${errorMessage}`);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} style={{
          marginLeft: "auto",
        }}>
        <span>Update PDF Document Type</span>
      </Button>

      <Modal
        size="tiny"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>Update PDF Document Type</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Checkbox
                label="Passport Book"
                name="book"
                checked={formData.book}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Passport Card"
                name="card"
                checked={formData.card}
                onChange={handleChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button 
            positive 
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <>
                <Loader active inline size="tiny" /> Updating...
              </>
            ) : (
              'Submit'
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default PassportDocumentType;
