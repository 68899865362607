import axios from "../httpClient";

function getBrochures(api, id, language) {
  return axios.get(`${api}/documents/applicants/${id}`, {
    params: { documentType: "Brochure"},
  });
}

function getDocumentsAgent(api, id) {
  return axios.get(`${api}/documents/applicants/${id}`, {
    params: {
      documentType: "Agent uploaded",
    },
  });
}

function getDocumentsClient(api, id) {
  return axios.get(`${api}/documents/applicants/${id}`, {
    params: {
      documentType: "Client uploaded",
    },
  });
}

function getDocumentsBots(api, id) {
  return axios.get(`${api}/documents/applicants/${id}`, {
    params: {
      documentType: "Bot downloaded",
    },
  });
}

function getDocumentsFinancial(api, id) {
  return axios.get(`${api}/documents/applicants/${id}`, {
    params: {
      documentType: "Financial documents",
    },
  });
}

function addDocument(api, id, name, documentType, file) {
  const formData = new FormData();

  formData.append("name", name);
  formData.append("documentType", documentType);
  formData.append("fileName", "document");
  formData.append("pdf", file);

  return axios.post(`${api}/documents/applicants/${id}/upload`, formData);
}

function addJsonFile(api, id, name, documentType, file) {
  const formData = new FormData();

  formData.append("name", name);
  formData.append("documentType", documentType);
  formData.append("fileName", "json");
  formData.append("json", file);

  return axios.post(`${api}/documents/applicants/${id}/upload-json/`, formData);
}

function updateActiveVersion(api, documentId, versionId) {
  return axios.patch(`${api}/documents/${documentId}/versions/${versionId}`);
}

function deleteDocumentVersion(api, documentId, versionId) {
  return axios.delete(`${api}/documents/${documentId}/versions/${versionId}`);
}

function deleteDocumentAllVersions(api, documentId) {
  return axios.delete(`${api}/documents/${documentId}`);
}

function updateDocument(api, documentId, document) {
  return axios.patch(`${api}/documents/${documentId}`, document);
}

function getFile(key) {
  return axios.get(`ucc-files`, {
    params: {
      key,
    },
  });
}

function sendEmail(
  api,
  applicantId,
  applicantDocumentIds,
  cc,
  subject,
  templateName,
  templateId,
) {
  return axios.post(`${api}/documents/applicants/${applicantId}/send-to-customer`, {
    applicantDocumentIds,
    cc,
    subject,
    templateName,
    templateId,
  });
}

function previewEmail(api, applicantId, applicantDocumentIds, language, department) {
  return axios.post(`${api}/documents/applicants/${applicantId}/email-preview?language=${language}&department=${department}`, {
    applicantDocumentIds,
  });
}

function downloadEmbassyDocuments(api, applicantId) {
  return axios.post(`${api}/documents/applicants/${applicantId}/run-embassy-documents-bot`)
}

function sendDocumentRequest(api, applicantId, selectedDocuments) {
  return axios.post(`${api}/documents-upload`, {
    applicantId,
    requiredDocuments: selectedDocuments,
  });
}

function passportTriggerPdf(api, application) {
  return axios.post(`/olympus/passport/trigger-pdf-fill`, {
    api,
    application,
  });
}

function updateDocumentTypeForPdf(api, applicantId, documentType) {
  return axios.post(`${api}/applicant/${applicantId}/document-type-for-pdf`, documentType);
}

// TODO: use enum for document type
/**
 * Retrieves a document for a given application and document ID.
 *
 * @param {string} applicationId - The ID of the application.
 * @param {string} documentId - The ID of the document to be retrieved.
 * @param {string} identity - The identity of the user, by default in most cases it will be their email.
 * @param {string} key - The s3 key of the document.
 * @param {string} [type='WasabiDocumentAccess'] - The type of document access.
 * @returns {Promise} - A promise that resolves with the retrieved document.
 */
function logRetrieveDocument(applicationId, documentId, identity, key, type = 'DocumentAccess') {
  return axios.post('/olympus/secret', {
    applicationId,
    documentId,
    identity,
    key,
    type
    });
}

export default {
  getBrochures,
  getDocumentsAgent,
  getDocumentsClient,
  getDocumentsBots,
  getDocumentsFinancial,
  addDocument,
  updateActiveVersion,
  deleteDocumentVersion,
  deleteDocumentAllVersions,
  sendEmail,
  previewEmail,
  updateDocument,
  downloadEmbassyDocuments,
  getFile,
  sendDocumentRequest,
  addJsonFile,
  passportTriggerPdf,
  logRetrieveDocument,
  updateDocumentTypeForPdf
};
