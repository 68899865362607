import React from "react";
import ConfirmPopup from "../../../../../../../../../../components/ConfirmPopup";
import { Button, Loader } from "semantic-ui-react";
import apis from "../../../../../../../../../../apis";
import toastr from "toastr";
import { getApplicantByIdApi } from "../../../../../../../../../../apis/applicantApi";

const DownloadPassportPdf = ({ applicant, api }) => {
  const [loading, setLoading] = React.useState(false);

  const getApplication = async () => { 
    try {
      const { data } = await getApplicantByIdApi(applicant.id, {}, api);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const generatePassportPdf = async () => {
    setLoading(true);
    const application = await getApplication();
    try {
      await apis.applicantDocumentHandler.passportTriggerPdf(api, application);
      toastr.success(`Passport PDF generated successfully.`);
    } catch (error) {
      if (error.response && error.response.data) {
        const { error: errorMessage } = error.response.data;
        error.message = errorMessage;
      }
      toastr.error(`Failed to generate the passport PDF: ${error.message}`);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfirmPopup
      content="Are you sure you want to generate the passport PDF?"
      callback={generatePassportPdf}
    >
      <Button
        style={{
          marginLeft: "auto",
        }}
        disabled={loading}
      >
        {loading && (
          <>
            <Loader active inline size="tiny" /> Generating PDF...
          </>
        )}
        {!loading && <span>Trigger Passport PDF Generation</span>}
      </Button>
    </ConfirmPopup>
  );
};

export default DownloadPassportPdf;
