import React, { useState } from "react";
import { Button, Input, Modal, Message } from "semantic-ui-react";
import axios from "axios";
import {
  fetchOrderInformation,
  addPurchase,
} from "../../../../../../../../../apis/applicantApi";
import toastr from "toastr";

const AddPurchase = ({ applicantId, api, onPurchaseAdded }) => {
  const [orderId, setOrderId] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fetchOrderInfo = async () => {
    setLoading(true);
    setOrderDetails(null);
    setError("");
    try {
      const response = await fetchOrderInformation(applicantId, api, orderId);
      setOrderDetails(response.data);
    } catch (err) {
      setOrderDetails(null);
      setError(
        "Failed to fetch order information. Please check the Order ID and try again.",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAddPurchase = async () => {
    setLoading(true);
    setError("");
    try {
      await addPurchase(applicantId, api, orderId);
      toastr.success("Purchase added successfully");
      setShowModal(false);
      setOrderDetails(null);
      setOrderId("");
    } catch (err) {
      setError(
        err.response.data.message ||
          "Failed to add purchase. Please try again.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button primary onClick={() => setShowModal(true)}>
        Add Purchase
      </Button>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setOrderDetails(null);
          setOrderId("");
        }}
        size="small"
      >
        <Modal.Header>Add Purchase</Modal.Header>
        <Modal.Content>
          <Input
            placeholder="Enter Order ID"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            action={{
              color: "blue",
              labelPosition: "right",
              icon: "search",
              content: "Fetch Order Info",
              onClick: fetchOrderInfo,
              loading: loading,
              disabled: loading,
            }}
          />
          {error && <Message negative>{error}</Message>}
          {orderDetails && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "var(--background-secondary, #f0f0f0)",
                color: "var(--text-primary, #000)",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid var(--border-color, #ddd)",
              }}
            >
              <h4
                style={{ width: "100%", textAlign: "center", padding: "10px" }}
              >
                Order Details
              </h4>
              <div style={{ flex: "1 1 50%" }}>
                <p>
                  <b>Transaction ID:</b> {orderDetails.transactionId}
                </p>
                <p>
                  <b>Amount:</b> {orderDetails.amount}
                </p>
                <p>
                  <b>Captured Amount:</b> {orderDetails.capturedAmount}
                </p>
                <p>
                  <b>Refunded Amount:</b> {orderDetails.refundedAmount}
                </p>
                <p>
                  <b>Chargeback Amount:</b> {orderDetails.chargebackAmount}
                </p>
                <p>
                  <b>Email:</b> {orderDetails.email}
                </p>
                <p>
                  <b>Site ID:</b> {orderDetails.siteId}
                </p>
                <p>
                  <b>Zendesk ID:</b> {orderDetails.zendeskId}
                </p>
              </div>
              <div style={{ flex: "1 1 50%" }}>
                <p>
                  <b>Applicant ID:</b> {orderDetails.applicantId}
                </p>
                <p>
                  <b>First Name:</b> {orderDetails.firstName}
                </p>
                <p>
                  <b>Last Name:</b> {orderDetails.lastName}
                </p>
                <p>
                  <b>Product Name:</b> {orderDetails.productName}
                </p>
                <p>
                  <b>Payment Method:</b> {orderDetails.paymentMethod}
                </p>
                <p>
                  <b>Payment Status:</b> {orderDetails.paymentStatus}
                </p>
                <p>
                  <b>Payment Gateway:</b> {orderDetails.paymentGateway}
                </p>
                <p>
                  <b>Date:</b> {orderDetails.createdAt}
                </p>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
              setOrderDetails(null);
              setOrderId("");
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          {orderDetails && (
            <Button
              primary
              onClick={handleAddPurchase}
              loading={loading}
              disabled={loading || !orderDetails}
            >
              Associate Purchase
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default AddPurchase;
