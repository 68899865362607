import React, { useMemo, useCallback, useState } from "react";
import { Select, Button, Modal, Input } from "semantic-ui-react";
import {
  cancelAuthorizationApi,
  captureAuthorizationApi,
  changeStatusApi,
  refundTransactionApi,
  removeBotRunApi,
  convertPassportApplicationApi,
} from "../../../../../../../../apis/applicantApi";
import ConfirmPopup from "../../../../../../../../components/ConfirmPopup";
import { SOCKET_EVENTS } from "../../../../../../../../constants/socketEvents";
import socketService from "../../../../../../../../services/socketService";
import { filter } from "rxjs/operators";
import { API_NAMES } from "../../constants/apiNames";
import DSLettersAPI from "./components/DSLettersAPI";
import StatusChecker from "./components/StatusChecker";
import { useParams, useHistory } from "react-router-dom";
import ApplicantView from "../../../components/ApplicantView";
import { useCallbackRef } from "../../../../../../../../hooks/useCallbackRef.hook";
import { useSubscription } from "../../../../../../../../hooks/useSubscription.hook";
import {
  APPLICANT_STATUSES,
  getApplicantStatusString,
} from "../../../constants/applicantStatuses";
import { NavLink } from "react-router-dom";
import apis from "../../../../../../../../apis";
import { EVENT_TYPE, LOG_TYPE } from "../../../../../../../../constants/logs";
import { useSelector } from "react-redux";
import { TRANSACTION_ACTION_TYPES } from "./components/constants";
import { ROLES } from "../../../../../../../../constants/rolesConstants";
import { getDateFormat } from "../../../../../../../../common/date-format";
import toastr from "toastr";
import PassportAppointmentOptions from "./components/PassportAppointmentOptions";
import { ApplicantNotesService } from "../../../../../../../../services/applicantNotes";
import AddPurchase from "./components/AddPurchase";

const ApplicantEdit = ({ api }) => {
  const zendeskView = window.location.href.includes(`zendesk/${api}`);
  const { id } = useParams();
  const history = useHistory();
  const [newStatus, setNewStatus] = useState();
  const [order, setOrder] = useState();
  const [actionType, setActionType] = useState();
  const [amount, setAmount] = useState("");
  const [applicantView, applicantViewRef] = useCallbackRef();
  const user = useSelector((state) => state.currentUser);
  const [isConverting, setIsConverting] = useState(false);

  const ApplicantNotes = useMemo(() => {
    if (id) {
      return new ApplicantNotesService(api, id, user);
    }
  }, [id]);

  const createEventLog = useCallback(
    async (status) => {
      try {
        await apis.logs.createEventLog({
          logType: LOG_TYPE.INSIDE_APPLICANT,
          eventType: EVENT_TYPE.STATUS_CHANGE,
          userId: user.id,
          applicantId: id,
          visaType: api,
          actions: [
            {
              propertyName: "status",
              oldValue: getApplicantStatusString(
                applicantView.editingApplicant.status,
              ),
              newValue: getApplicantStatusString(status),
            },
          ],
        });
      } catch (e) {
        console.error(e);
      }
    },
    [api, applicantView, id, user],
  );

  const changeStatus = useCallback(
    async (status) => {
      if (!applicantView) {
        return;
      }
      try {
        await changeStatusApi(id, status, api);
        createEventLog(status);
        ApplicantNotes.createNote(
          {
            newStatus: getApplicantStatusString(status),
            oldStatus: getApplicantStatusString(
              applicantView.editingApplicant.status,
            ),
          },
          "changeStatus",
        );
      } catch (e) {
        console.error(e);
      }
    },
    [applicantView, id, api, createEventLog],
  );

  const captureAuthorization = useCallback(
    async (orderId, amount) => {
      try {
        await captureAuthorizationApi(id, api, orderId, amount);
        toastr.success("Succesfully captured authorization");
      } catch (err) {
        let message = err.message;
        if (err.response) {
          message = err.response.data.message;
        }
        toastr.error(message, "Failed to capture authorization");
      }
    },
    [api, id],
  );

  const cancelAuthorization = useCallback(
    async (orderId) => {
      try {
        await cancelAuthorizationApi(id, api, orderId);
        toastr.success("Succesfully canceled authorization");
      } catch (err) {
        let message = err.message;
        if (err.response) {
          message = err.response.data.message;
        }
        toastr.error(message, "Failed to cancel authorization");
      }
    },
    [api, id],
  );

  const refundTransaction = useCallback(
    async (orderId, amount) => {
      try {
        await refundTransactionApi(id, api, orderId, amount);
        toastr.success("Succesfully refunded transaction");
      } catch (err) {
        let message = err.message;
        if (err.response) {
          message = err.response.data.message;
        }
        toastr.error(message, "Failed to refund transaction");
      }
    },
    [api, id],
  );

  const convertPassportApplication = useCallback(async () => {
    setIsConverting(true);
    try {
      const response = await convertPassportApplicationApi(id, api);
      if (response.data && response.data.application) {
        const newApi = api === API_NAMES.DS11 ? API_NAMES.DS82 : API_NAMES.DS11;
        toastr.success(response.data.message);
        history.push(`/${newApi}/applicant/${response.data.application._id}`);
      }
    } catch (err) {
      let message = err.message;
      if (err.response) {
        message = err.response.data.message;
      }
      toastr.error(message, "Failed to convert passport application");
    } finally {
      setIsConverting(false);
    }
  }, [api, id, history]);

  const customHeader = useMemo(() => {
    if (!applicantView || !applicantView.editingApplicant) {
      return null;
    }
    const { editingApplicant } = applicantView;
    let initialStatusesCondition =
      api !== "inv_letter"
        ? api === API_NAMES.ESTA
          ? editingApplicant.status !== 50
          : true
        : true;
    const statuses = initialStatusesCondition
      ? [
          { key: 10, value: 10, text: "More Information Required" },
          { key: 18, value: 18, text: "Processed Manually" },
        ]
      : [];
    if (api === API_NAMES.DS160) {
      statuses.push({ key: 45, value: 45, text: "Administrative Processing" });
      statuses.push({ key: 35, value: 35, text: "Personal Embassy Account" });
      statuses.push({ key: 19, value: 19, text: "Embassy Account Created" });
      statuses.push({ key: 21, value: 21, text: "Interview Scheduled" });
      statuses.push({ key: 36, value: 36, text: "Biometrical Appointment" });
      statuses.push({ key: 39, value: 39, text: "Drop off Appointment" });
      statuses.push({ key: 40, value: 40, text: "Follow-up Appointment" });
      statuses.push({ key: 37, value: 37, text: "Interview Waiver" });
      statuses.push({ key: 22, value: 22, text: "Visa Approved" });
      statuses.push({ key: 38, value: 38, text: "Visa Rejected" });
      statuses.push({ key: 41, value: 41, text: "Passport in Transit" });
      statuses.push({ key: 24, value: 24, text: "Passport Received" });
    }
    if (api === API_NAMES.SCHENGEN) {
      statuses.push({ key: 45, value: 45, text: "Administrative Processing" });
      statuses.push({ key: 19, value: 19, text: "Embassy Account Created" });
      statuses.push({ key: 21, value: 21, text: "Interview Scheduled" });
      statuses.push({ key: 38, value: 38, text: "Visa Rejected" });
      statuses.push({ key: 23, value: 23, text: "VISA Issued" });
      statuses.push({ key: 41, value: 41, text: "Passport in Transit" });
      statuses.push({ key: 24, value: 24, text: "Passport Received" });
    }
    if (api === API_NAMES.GE) {
      statuses.push({ key: 53, value: 53, text: "Appointment Scheduled" });
      statuses.push({ key: 7, value: 7, text: "Approved" });
      statuses.push({ key: 8, value: 8, text: "Rejected" });
    }
    if ([API_NAMES.DS11, API_NAMES.DS64, API_NAMES.DS82].includes(api)) {
      statuses.push({ key: 53, value: 53, text: "Appointment Scheduled" });
      statuses.push({ key: 63, value: 63, text: "Documents mailed" });
      statuses.push({ key: 7, value: 7, text: "Approved" });
      statuses.push({ key: 41, value: 41, text: "Passport in Transit" });
      statuses.push({ key: 62, value: 62, text: "Delivered" });
      statuses.push({ key: 64, value: 64, text: "Ready for PKO" });
      statuses.push({ key: 65, value: 65, text: "Photo not uploaded" });
      statuses.push({ key: 66, value: 66, text: "Photo accepted" });
      statuses.push({ key: 67, value: 67, text: "Photo invalid" });
      statuses.push({ key: 68, value: 68, text: "Kit printed" });
      statuses.push({ key: 69, value: 69, text: "Kit mailed" });
      statuses.push({ key: 70, value: 70, text: "Agency: QuickPassport" });
    }

    if (api === API_NAMES.ESTA) {
      statuses.push({ key: 7, value: 7, text: "Approved" });
      statuses.push({ key: 8, value: 8, text: "Rejected" });
    }

    statuses.push({ key: 11, value: 11, text: "Escalated" });
    statuses.push({ key: 12, value: 12, text: "Refunded" });
    statuses.push({ key: 48, value: 48, text: "Partially Refunded" });
    statuses.push({ key: 20, value: 20, text: "Chargeback" });
    statuses.push({ key: 47, value: 47, text: "Canceled Authorization" });

    const disabledStatuses = [
      newStatus,
      APPLICANT_STATUSES["Bot in progress"],
      APPLICANT_STATUSES["Bot in queue"],
    ];

    return (
      <React.Fragment>
        <div>
          <Select
            placeholder="Select new status"
            options={statuses}
            onChange={(ev, { value }) => setNewStatus(value)}
            value={newStatus}
          />
          <ConfirmPopup
            content="Are you sure you want to change applicant status?"
            callback={() => {
              changeStatus(newStatus);
            }}
          >
            <Button
              primary
              disabled={
                newStatus === null ||
                disabledStatuses.includes(editingApplicant.status)
              }
            >
              Submit status change
            </Button>
          </ConfirmPopup>
        </div>

        <div>
          {[API_NAMES.ESTA, API_NAMES.VN, API_NAMES.ETA].includes(api) && (
            <StatusChecker
              api={api}
              id={id}
              applicantStatus={editingApplicant.status}
            />
          )}
          {[API_NAMES.DS160, API_NAMES.INV].includes(api) && (
            <DSLettersAPI
              applicantId={id}
              api={api}
              applicantStatus={editingApplicant.status}
              applicationCountry={editingApplicant.applicationCountry}
            ></DSLettersAPI>
          )}

          <AddPurchase applicantId={id} api={api} />

          {[API_NAMES.DS11, API_NAMES.DS82].includes(api) && (
            <PassportAppointmentOptions applicantId={id} api={api} />
          )}

          {[API_NAMES.DS11, API_NAMES.DS82].includes(api) && (
            <ConfirmPopup
              content={`Are you sure you want to convert this application to ${
                api === API_NAMES.DS11 ? "DS82" : "DS11"
              }?`}
              callback={convertPassportApplication}
            >
              <Button primary loading={isConverting} disabled={isConverting}>
                Convert to {api === API_NAMES.DS11 ? "DS82" : "DS11"}
              </Button>
            </ConfirmPopup>
          )}

          {!!editingApplicant.paymentInfoHistory?.length && (
            <ConfirmPopup
              cancelButton={null}
              callback={async () => {}}
              confirmButton="Close"
              content={
                <React.Fragment>
                  <Modal.Header>Actions</Modal.Header>
                  <Modal.Content>
                    <Modal.Description
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Select
                        placeholder="Select order"
                        options={editingApplicant.paymentInfoHistory.map(
                          (el) => ({
                            value: el,
                            text: `Original amount: ${el.amount || "unknown"}${
                              el.currency || ""
                            } | Order ID: ${el.orderId || "unknown"} | Date: ${
                              el.createdAt
                                ? getDateFormat(el.createdAt)
                                : "unknown"
                            }`,
                          }),
                        )}
                        onChange={(ev, { value }) => setOrder(value)}
                        value={order}
                      />
                      <Select
                        placeholder="Select action"
                        options={Object.keys(TRANSACTION_ACTION_TYPES)
                          .filter((el) => {
                            const actionType = TRANSACTION_ACTION_TYPES[el];
                            return (
                              actionType !== TRANSACTION_ACTION_TYPES.REFUND ||
                              user.roles.some((role) =>
                                [
                                  ROLES.ADMIN,
                                  ROLES.BT_MANAGER,
                                  ROLES.BT_AGENT,
                                ].includes(role),
                              )
                            );
                          })
                          .map((el) => ({
                            text: TRANSACTION_ACTION_TYPES[el],
                            value: TRANSACTION_ACTION_TYPES[el],
                          }))}
                        onChange={(ev, { value }) => setActionType(value)}
                        value={actionType}
                        disabled={!order}
                      />
                      {[
                        TRANSACTION_ACTION_TYPES.CAPTURE,
                        TRANSACTION_ACTION_TYPES.REFUND,
                      ].includes(actionType) && (
                        <Input
                          value={amount}
                          type="number"
                          label={order?.currency || "USD"}
                          labelPosition="right"
                          name="amount"
                          onChange={(event, { value }) => setAmount(value)}
                        />
                      )}
                      {actionType === TRANSACTION_ACTION_TYPES.CAPTURE && (
                        <ConfirmPopup
                          content={`Are you sure you want to capture authorization for ${
                            amount || order.amount
                          }${order?.currency || "USD"}?`}
                          callback={() =>
                            captureAuthorization(
                              order.orderId,
                              amount || order.amount,
                            )
                          }
                        >
                          <Button>Capture Authorization</Button>
                        </ConfirmPopup>
                      )}
                      {actionType === TRANSACTION_ACTION_TYPES.CANCEL && (
                        <ConfirmPopup
                          content="Are you sure you want to cancel authorization?"
                          callback={() => cancelAuthorization(order.orderId)}
                        >
                          <Button>Cancel Authorization</Button>
                        </ConfirmPopup>
                      )}
                      {user.roles.some((role) =>
                        [
                          ROLES.ADMIN,
                          ROLES.BT_MANAGER,
                          ROLES.BT_AGENT,
                        ].includes(role),
                      ) &&
                        actionType === TRANSACTION_ACTION_TYPES.REFUND && (
                          <ConfirmPopup
                            content={`Are you sure you want to refund transaction for ${
                              amount || order.amount
                            } ${order?.currency || "USD"}?`}
                            callback={() =>
                              refundTransaction(
                                order.orderId,
                                amount || order.amount,
                              )
                            }
                          >
                            <Button>Refund Transaction</Button>
                          </ConfirmPopup>
                        )}
                    </Modal.Description>
                  </Modal.Content>
                </React.Fragment>
              }
            >
              <Button>Actions</Button>
            </ConfirmPopup>
          )}

          <NavLink
            to={
              zendeskView
                ? `/${api}/applicant/${id}/documents`
                : `${id}/documents`
            }
            target={zendeskView && "_blank"}
            className="ui button blue"
          >
            Documents handler
          </NavLink>
        </div>
      </React.Fragment>
    );
  }, [
    applicantView,
    api,
    newStatus,
    id,
    order,
    actionType,
    amount,
    user,
    zendeskView,
    changeStatus,
    captureAuthorization,
    cancelAuthorization,
    refundTransaction,
    convertPassportApplication,
    isConverting,
  ]);

  const customSubmits = useMemo(() => {
    if (!applicantView || !applicantView.editingApplicant) {
      return null;
    }
    const { editingApplicant } = applicantView;
    let canRestart = true;
    let buttonText;
    let content = "Are you sure you want to mark applicant for submission?";
    let callback = () => changeStatus(APPLICANT_STATUSES["Pending submission"]);

    if (editingApplicant.status === APPLICANT_STATUSES["Bot in queue"]) {
      buttonText = "Remove bot from queue.";
      content = "Are you sure you want bot not to process this application?";
      callback = () => removeBotRunApi(id, api);
    } else if (
      editingApplicant.status === APPLICANT_STATUSES["Bot in progress"]
    ) {
      canRestart = false;
      buttonText = "Bot is already running, you can't stop it.";
    } else if ([0, 3].includes(editingApplicant.status)) {
      buttonText = "Mark as ready for submission";
    } else if (
      editingApplicant.status === APPLICANT_STATUSES["Pending submission"]
    ) {
      canRestart = false;
      buttonText = "Pending submission";
    } else if (
      editingApplicant.status === APPLICANT_STATUSES["Submitted to IVISA"]
    ) {
      canRestart = false;
      buttonText = "Already submitted to iVisa";
    } else {
      buttonText = "Restart applicant status to 'Ready for submission'";
    }

    return (
      <ConfirmPopup content={content} callback={callback}>
        <Button primary disabled={!canRestart}>
          {buttonText}
        </Button>
      </ConfirmPopup>
    );
  }, [api, applicantView, changeStatus, id]);

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        if (!applicantView) {
          return;
        }
        const { changeState } = applicantView;
        const { currentStage } = event.data;
        changeState("currentStage", currentStage);
      },
      [applicantView],
    ),
    [
      filter(
        (event) =>
          (event.type === SOCKET_EVENTS.ds160.DS_STAGE_CHANGED ||
            event.type === SOCKET_EVENTS.esta.ESTA_STAGE_CHANGED) &&
          id === event.data.applicantId,
      ),
    ],
  );

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        if (!applicantView) {
          return;
        }
        const { changeState, editingApplicant } = applicantView;
        const { botError } = event.data;
        changeState("errors", [botError, ...editingApplicant.errors]);
      },
      [applicantView],
    ),
    [
      filter(
        (event) =>
          event.type === SOCKET_EVENTS[api].BOT_ERROR &&
          id === event.data.applicantId,
      ),
    ],
  );

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        if (!applicantView) {
          return;
        }
        const { changeState } = applicantView;
        const { images } = event.data;
        changeState("imageObjects", images);
      },
      [applicantView],
    ),
    [
      filter(
        (event) =>
          event.type === SOCKET_EVENTS[api].NEW_IMAGE &&
          id === event.data.applicantId,
      ),
    ],
  );

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        if (!applicantView) {
          return;
        }
        const { changeState } = applicantView;
        const { status } = event.data;
        changeState("status", APPLICANT_STATUSES[status] || status);
      },
      [applicantView],
    ),
    [
      filter(
        (event) =>
          event.type === SOCKET_EVENTS[api].STATUS_CHANGED &&
          id === event.data._id,
      ),
    ],
  );

  return (
    <ApplicantView
      ref={applicantViewRef}
      customHeader={customHeader}
      customSubmits={customSubmits}
      applicantId={id}
      api={api}
      specifiedIcons={{
        emails: true,
        images: true,
        errors: true,
        pdfs: true,
      }}
    />
  );
};

export default ApplicantEdit;
