import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "semantic-ui-react";
import CustomSelect from "../../../../../../../components/common/CustomSelect";
import {
  getCountryList,
  getConsulatesList,
} from "../../../../../../../apis/interviewsApi";
import "./EmbassyForm.styles.scss";

const EmbassyForm = ({ embassy }) => {
  const [item, setItem] = useState(embassy);
  const [countryOptions, setCountryOptions] = useState([]);
  const [consulateOptions, setConsulateOptions] = useState([]);

  const fetchCountries = useCallback(async () => {
    try {
      const { data } = await getCountryList();
      setCountryOptions(
        data.map((country) => ({ value: country, text: country })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchConsulates = useCallback(async (country) => {
    try {
      const { data } = await getConsulatesList(country);
      setConsulateOptions(
        data.map((consulate) => ({ value: consulate, text: consulate })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const changeCurrentItemPropertyValue = useCallback(
    (property, value, multipleSelect) => {
      multipleSelect
        ? setItem((currItem) => ({
            ...currItem,
            [property]: [...(item[property] || []), value],
          }))
        : setItem((currItem) => ({ ...currItem, [property]: value }));
      if (property === "country")
        setItem((currItem) => ({ ...currItem, usConsulate: "" }));
      if (property === "type")
        setItem((currItem) => ({
          ...currItem,
          usConsulate: undefined,
          accountType: "",
        }));
    },
    [item],
  );

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    item.country && fetchConsulates(item.country);
  }, [fetchConsulates, item.country]);

  const accountTypeOptions = useMemo(() => {
    if (item.type === "A")
      return [
        { text: "Interview Scheduling", value: "Interview Scheduling" },
        { text: "Drop-off Appointments", value: "Drop-off Appointments" },
      ];
    else return [{ text: "Default", value: "Default" }];
  }, [item.type]);

  return (
    <div>
      <Form.Field>
        <label>Type</label>
        <CustomSelect
          item={item}
          setItem={setItem}
          changeCurrentItemPropertyValue={changeCurrentItemPropertyValue}
          itemPropName="type"
          options={[
            { value: "A", text: "A" },
            { value: "B", text: "B" },
            { value: "C", text: "C" },
          ]}
          itemProp={item.type}
        />
      </Form.Field>

      {item.type && (
        <>
          <Form.Field>
            <label>Country</label>
            <CustomSelect
              item={item}
              setItem={setItem}
              changeCurrentItemPropertyValue={changeCurrentItemPropertyValue}
              itemPropName="country"
              options={countryOptions}
              itemProp={item.country}
            />
          </Form.Field>

          {item.country && (
            <>
              <Form.Field>
                <label>U.S. Consulates</label>
                <CustomSelect
                  item={
                    item.type === "B" &&
                    item.usConsulate &&
                    typeof item.usConsulate === "string"
                      ? {
                          ...item,
                          usConsulate: consulateOptions
                            .map((el) => el.value)
                            .filter((el) => item.usConsulate.includes(el)),
                        }
                      : item
                  }
                  setItem={setItem}
                  changeCurrentItemPropertyValue={
                    changeCurrentItemPropertyValue
                  }
                  itemPropName="usConsulate"
                  options={consulateOptions}
                  itemProp={item.usConsulate}
                  multiple={item.type === "B"}
                />
              </Form.Field>

              <Form.Field>
                <label>Interview Type</label>
                <CustomSelect
                  item={item}
                  setItem={setItem}
                  changeCurrentItemPropertyValue={
                    changeCurrentItemPropertyValue
                  }
                  itemPropName="accountType"
                  options={accountTypeOptions}
                  itemProp={item.accountType}
                />
              </Form.Field>

              {item.type !== "C" && (
                <>
                  <Form.Field>
                    <Form.Input
                      value={item.embassyLink || ""}
                      label={`Embassy Url ${item.applicationCountry}!`}
                      placeholder={`Embassy Url ${item.applicationCountry}!`}
                      type="input"
                      name="embassyLink"
                      onChange={(event, field) =>
                        changeCurrentItemPropertyValue(
                          "embassyLink",
                          field.value,
                        )
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      value={item.embassyEmail || ""}
                      label="Embassy Email"
                      placeholder="Embassy Email"
                      type="input"
                      name="embassyEmail"
                      onChange={(event, field) =>
                        changeCurrentItemPropertyValue(
                          "embassyEmail",
                          field.value,
                        )
                      }
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      value={item.embassyPassword || ""}
                      label="Embassy Password"
                      placeholder="Embassy Password"
                      type="input"
                      name="embassyPassword"
                      onChange={(event, field) =>
                        changeCurrentItemPropertyValue(
                          "embassyPassword",
                          field.value,
                        )
                      }
                    />
                  </Form.Field>
                </>
              )}

              <Form.Field>
                <Form.Checkbox
                  label="Account disabled"
                  name="accountDisabled"
                  value={item.accountDisabled ? "true" : "false"}
                  checked={item.accountDisabled}
                  onChange={() => {
                    changeCurrentItemPropertyValue(
                      "accountDisabled",
                      !item.accountDisabled,
                    );
                  }}
                />
              </Form.Field>
            </>
          )}
        </>
      )}
    </div>
  );
};

EmbassyForm.defaultProps = {
  embassy: {
    country: "",
    usConsulate: "",
    embassyEmail: "",
    embassyPassword: "",
    accountType: "",
    type: null,
    accountDisabled: false,
  },
};

export default EmbassyForm;
